<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col
        xm="6"
        sm="6"
        lg="5"
        xl="4"
        class="login-bg-cc d-flex flex-column justify-content-center align-items-center p-0"
      >
        <b-img fluid src="@/assets/images/assignor/cs-top-image.svg" class="w-100" />
        <div class="px-5 d-flex flex-column align-items-center p-5 bg-white">
          <b-img fluid :src="$route.meta.layoutPage.appLogoGrande" alt="register Side Image" />
          <h4 class="mt-3 mb-0 side-bg-text">
            Unimos tecnologia para dar autonomia de dados para Investidores, Cedentes e fornecedores
            parceiros.
          </h4>
        </div>
        <b-img fluid src="@/assets/images/assignor/cs-bottom-image.svg" class="w-100" />
      </b-col>

      <b-col
        xm="6"
        sm="6"
        lg="7"
        xl="8"
        class="d-flex auth-bg auth-padding flex-column justify-content-between"
      >
        <div class="d-flex justify-content-end">
          <b-button
            size="sm"
            variant="outline"
            class="auth-back-button d-flex align-items-center text-dark"
            @click="goToSite"
          >
            <feather-icon icon="ArrowLeftIcon" size="24" class="mr-1" />
            Ir para o site
          </b-button>
        </div>
        <router-view />
        <div>
          <div
            class="d-flex justify-content-end align-items-center cursor-pointer"
            @click="goToWhatsApp"
          >
            <span>Fale pelo<br />WhatsApp</span>
            <b-img src="@/assets/images/whats-app.svg" class="ml-1" />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LayoutFull from '@core/layouts/layout-full/LayoutFull.vue'
import { BRow, BCol, BImg, BButton } from 'bootstrap-vue'

export default {
  name: 'LayoutAuthAssignor',
  components: {
    LayoutFull,
    BButton,
    BRow,
    BCol,
    BImg,
  },
  methods: {
    goToSite() {
      window.location.href = '/'
    },
    goToWhatsApp() {
      window.open('https://api.whatsapp.com/send?phone=551130343417', '_blank')
    },
  },
}
</script>

<style lang="scss">
.login-bg-cc {
  background: #00af90;
}

.side-bg-text {
  color: #66788b;
}

.auth-padding {
  padding: 4rem;
}

.auth-back-button {
  background-color: transparent !important;
  border: 1px solid #1eb499 !important;
}
</style>
